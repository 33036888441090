import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'LQTAMN1-U2-P24-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: '',
        title: '',
        color: '',
      },
    ],

    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '275px',
              left: '358px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '331px',
              left: '705px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '482px',
              left: '357px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '466px',
              left: '705px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '715px',
              left: '357px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '668px',
              left: '706px',
              width: 27,
              height: 27,
              borderRadius: '50%',

              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: ['0-5', '3-4', '1-2'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page24/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P24-E2',
    audio: 'img/FriendsPlus/Page24/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page24/E2/1.jpg' },
        {
          url: 'img/FriendsPlus/Page24/E2/2.jpg',
          audioUrl: 'img/FriendsPlus/Page24/Audio/tieude.e2.mp3',
        },
        { url: 'img/FriendsPlus/Page24/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page24/E2/4.jpg' },
        {
          url: 'img/FriendsPlus/Page24/E2/5.jpg',
          audioUrl: 'img/FriendsPlus/Page24/Audio/G.mp3',
        },
        { url: 'img/FriendsPlus/Page24/E2/6.jpg' },
      ],
      [
        // Column3
        {
          url: 'img/FriendsPlus/Page24/E2/7.jpg',
          audioUrl: 'img/FriendsPlus/Page24/Audio/girl.mp3',
        },
        {
          url: 'img/FriendsPlus/Page24/E2/8.jpg',
          audioUrl: 'img/FriendsPlus/Page24/Audio/guitar.mp3',
        },
        { url: 'img/FriendsPlus/Page24/E2/9.jpg' },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB1-U3-P24-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page24/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page24/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page24/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page24/E3/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page24/E3/6.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page24/E3/8.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page24/E3/9.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page24/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page24/E3/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page24/E3/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/13.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/14.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page24/E3/15.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/16.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page24/E3/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table'],
      },
    ],
  },
}

export default json
