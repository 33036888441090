import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P15-E1",
    audio: "",
    video: "Videos/Unit 4_Track 9_What's in the box.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "img/FriendsPlus/Page15/E1/1.png",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E1/a.png",
        },
        {
          url: "img/FriendsPlus/Page15/E1/b.png",
          audioMainUrl: "Audios/P15/Track 4.mp3",
        },
        {
          url: "img/FriendsPlus/Page15/E1/c.png",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E1/d.png",
        },
      ],
      [
        { url: "img/FriendsPlus/Page15/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page15/E1/3.jpg",
          audioUrl: "/Audios/P15/one duck.mp3",
        },
        { url: "img/FriendsPlus/Page15/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page15/E1/5.jpg",
          audioUrl: "/Audios/P15/how many duck.mp3",
        },
        { url: "img/FriendsPlus/Page15/E1/6.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P15-E2",
    audio: "",
    video: "Videos/Unit 4_Track 9_What's in the box.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "img/FriendsPlus/Page15/E2/1.png",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E2/a.png",
        },
        {
          url: "img/FriendsPlus/Page15/E2/b.png",
          audioMainUrl: "Audios/P15/Track 4.mp3",
        },
        {
          url: "img/FriendsPlus/Page15/E2/c.png",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page15/E2/d.png",
        },
      ],
      [
        { url: "img/FriendsPlus/Page15/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page15/E2/3.jpg",
          audioUrl: "/Audios/P15/two duck.mp3",
        },
        { url: "img/FriendsPlus/Page15/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page15/E2/5.jpg",
          audioUrl: "/Audios/P15/how many duck.mp3",
        },
        { url: "img/FriendsPlus/Page15/E2/6.jpg" },
      ],
    ],
  },
};

export default json;
