import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P8-E1",
    audio: "",
    video: "Videos/Unit 2_Track 5_Three little ducks!.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page8/E1/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page8/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/3.jpg",
          audioMainUrl: "/Audios/P8/Track 2.mp3",
        },
        { url: "img/FriendsPlus/Page8/E1/4.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page8/E1/5.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "SB1-S-P8-E5",
    audio: "img/FriendsPlus/Page8/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page8/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page8/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/tieude.e2.mp3",
        },
        { url: "img/FriendsPlus/Page8/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page8/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page8/E2/5.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/B.mp3",
        },
        { url: "img/FriendsPlus/Page8/E2/6.jpg" },
      ],
      [
        // Column3
        {
          url: "img/FriendsPlus/Page8/E2/7.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/boy.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E2/8.jpg",
          audioUrl: "img/FriendsPlus/Page8/Audio/bat.mp3",
        },
        { url: "img/FriendsPlus/Page8/E2/9.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Starter",
    id: "SB1-S-P8-E5",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page8/E3/1.jpg" }]],
  },
};

export default json;
