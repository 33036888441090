import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P9-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page9/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page9/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page9/E1/3.jpg",
          audioUrl: "/Audios/P9/it mom.mp3",
        },
        { url: "img/FriendsPlus/Page9/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page9/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page9/E1/6.jpg",
          audioUrl: "/Audios/P9/who is this.mp3",
        },
        { url: "img/FriendsPlus/Page9/E1/7.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P9-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page9/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page9/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page9/E2/3.jpg",
          audioUrl: "/Audios/P9/it dad.mp3",
        },
        { url: "img/FriendsPlus/Page9/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page9/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page9/E2/6.jpg",
          audioUrl: "/Audios/P9/who is this.mp3",
        },
        { url: "img/FriendsPlus/Page9/E2/7.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN1-U1-P9-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page9/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page9/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page9/E3/3.jpg",
          audioUrl: "/Audios/P9/it baby.mp3",
        },

        { url: "img/FriendsPlus/Page9/E3/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page9/E3/5.jpg" },
        {
          url: "img/FriendsPlus/Page9/E3/6.jpg",
          audioUrl: "/Audios/P9/who is this.mp3",
        },
        {
          url: "img/FriendsPlus/Page9/E3/7.jpg",
          audioUrl: "/Audios/P9/who is this.mp3",
        },
        { url: "img/FriendsPlus/Page9/E3/8.jpg" },
      ],
    ],
  },
};

export default json;
