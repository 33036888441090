import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P10-E1",
    audio: "",
    video: "Videos/Unit 1_Track 3_Family duck!.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page10/E1/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page10/E1/3.jpg",
          audioMainUrl: "/Audios/P10/Track 3.mp3",
        },

        // Column2
        { url: "img/FriendsPlus/Page10/E1/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page10/E1/5.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P10-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page10/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/2.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page10/E2/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page10/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/6.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page10/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/9.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/10.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page10/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/12.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/13.jpg" },
      ],
      [
        // Column6
        {
          url: "img/FriendsPlus/Page10/E2/14.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/15.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/16.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/17.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/18.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/19.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page10/E2/20.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/21.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/22.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/23.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/24.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page10/E2/25.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/26.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/27.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/28.jpg",
        },
        { url: "img/FriendsPlus/Page10/E2/29.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page10/E2/30.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB1-U1-P10-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page10/E3/1.jpg" },
        { url: "img/FriendsPlus/Page10/E3/2.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page10/E3/3.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page10/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/5.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/6.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page10/E3/7.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/8.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/9.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page10/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/11.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/12.jpg" },
      ],
      [
        // Column6
        {
          url: "img/FriendsPlus/Page10/E3/13.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/14.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/15.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/16.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/17.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/18.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page10/E3/19.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/20.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/21.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/22.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/23.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page10/E3/24.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/25.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/26.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/27.jpg",
        },
        { url: "img/FriendsPlus/Page10/E3/28.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page10/E3/29.jpg" },
      ],
    ],
  },
};

export default json;
