import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "LQTAMN1-S-P6-E1",
    audio: "/Audios/P6/Track 1.mp3",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page6/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E1/2.jpg" },
        { url: "img/FriendsPlus/Page6/E1/3.jpg", audioUrl: "/Audios/P6/stand up.mp3" },
        { url: "img/FriendsPlus/Page6/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E1/5.jpg" },
        { url: "img/FriendsPlus/Page6/E1/6.jpg", audioUrl: "/Audios/P6/stand up.mp3" },
        { url: "img/FriendsPlus/Page6/E1/7.jpg" },
        { url: "img/FriendsPlus/Page6/E1/8.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page6/E1/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E1/10.jpg" },
        { url: "img/FriendsPlus/Page6/E1/11.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page6/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E1/13.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Starter",
    id: "SB1-S-P6-E2",
    audio: "img/FriendsPlus/Page6/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page6/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/2.jpg",
          audioUrl: "img/FriendsPlus/Page6/Audio/tieude.e2.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page6/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/5.jpg",
          audioUrl: "img/FriendsPlus/Page6/Audio/A.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/6.jpg" },
      ],
      [
        // Column3
        {
          url: "img/FriendsPlus/Page6/E2/7.jpg",
          audioUrl: "img/FriendsPlus/Page6/Audio/apple.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E2/8.jpg",
          audioUrl: "img/FriendsPlus/Page6/Audio/annie.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/9.jpg" },
      ],
    ],
  },
  // 3: { // Exercise num
  //   unit: 'Starter',
  //   id: 'SB1-S-P6-E3',
  //   audio: '',
  //   video: '',
  //   component: D1,
  //   // recorder: true,
  //   isAllowSubmit: false,
  //   question: [
  //   ],
  //   questionImage: [
  //     [
  //       { url: 'img/FriendsPlus/Page6/E3/1.jpg' },
  //     ],
  //   ]
  // },

  2: {
    // Exercise num
    unit: "Stater",
    id: "LQTAMN1-S-P6-E2",
    audio: "/Audios/P6/Track 1.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page6/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/2.jpg" },
        { url: "img/FriendsPlus/Page6/E2/3.jpg", audioUrl: "/Audios/P6/sit down.mp3" },
        { url: "img/FriendsPlus/Page6/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/5.jpg" },
        { url: "img/FriendsPlus/Page6/E2/6.jpg", audioUrl: "/Audios/P6/sit down.mp3" },
        { url: "img/FriendsPlus/Page6/E2/7.jpg" },
        { url: "img/FriendsPlus/Page6/E2/8.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page6/E2/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/10.jpg" }],
    ],
  },
};

export default json;
