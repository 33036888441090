import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U3-P28-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page28/E1/1.jpg" },
        { url: "img/FriendsPlus/Page28/E1/2.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page28/E1/4.jpg" },
        { url: "img/FriendsPlus/Page28/E1/5.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page28/E1/7.jpg" },
        { url: "img/FriendsPlus/Page28/E1/8.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E1/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E1/10.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U3-P28-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page28/E2/1.jpg" },
        { url: "img/FriendsPlus/Page28/E2/2.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E2/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page28/E2/4.jpg" },
        { url: "img/FriendsPlus/Page28/E2/5.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E2/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page28/E2/7.jpg" },
        { url: "img/FriendsPlus/Page28/E2/8.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E2/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E2/10.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U3-P28-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page28/E3/1.jpg" },
        { url: "img/FriendsPlus/Page28/E3/2.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E3/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E3/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page28/E3/5.jpg" },
        { url: "img/FriendsPlus/Page28/E3/6.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E3/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page28/E3/8.jpg" },
        { url: "img/FriendsPlus/Page28/E3/9.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E3/10.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page28/E3/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page28/E3/12.jpg" }],
    ],
  },
};

export default json;
