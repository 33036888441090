import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Review 3 + 4",
    id: "LQTAMN-U4-P41-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page41/E1/Key/answerKey.png",
    // hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 2,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page41/E1/1.jpg" },
        { url: "img/FriendsPlus/Page41/E1/2.jpg" },
        { url: "img/FriendsPlus/Page41/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page41/E1/4.jpg" },
        { url: "img/FriendsPlus/Page41/E1/5.jpg", input: 1 },
      ],
      [
        { url: "img/FriendsPlus/Page41/E1/6.jpg" },
        { url: "img/FriendsPlus/Page41/E1/7.jpg", input: 2 },
        { url: "img/FriendsPlus/Page41/E1/8.jpg" },
        { url: "img/FriendsPlus/Page41/E1/9.jpg", input: 3 },
        {
          url: "img/FriendsPlus/Page41/E1/10.jpg",
          input: 10,
        },
      ],
      [
        { url: "img/FriendsPlus/Page41/E1/11.jpg", input: 4 },
        { url: "img/FriendsPlus/Page41/E1/12.jpg" },
        { url: "img/FriendsPlus/Page41/E1/13.jpg", input: 5, isCorrect: true },
        {
          url: "img/FriendsPlus/Page41/E1/14.jpg",
          input: 6,
        },
      ],
      [
        { url: "img/FriendsPlus/Page41/E1/15.jpg" },
        { url: "img/FriendsPlus/Page41/E1/16.jpg", input: 7 },
        { url: "img/FriendsPlus/Page41/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page41/E1/18.jpg",
          input: 8,
          isCorrect: true,
        },
        { url: "img/FriendsPlus/Page41/E1/19.jpg", input: 9 },
      ],
    ],
  },
};

export default json;
