import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P16-E1",
    audio: "img/FriendsPlus/Page16/Audio/audio.e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page16/E1/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E1/2.jpg",
        },
        { url: "img/FriendsPlus/Page16/E1/3.jpg", audioUrl: "/Audios/P15/how many duck.mp3" },
        { url: "img/FriendsPlus/Page16/E1/4.jpg" },

        {
          url: "img/FriendsPlus/Page16/E1/5.jpg",
          audioUrl: "",
        },
        {
          url: "img/FriendsPlus/Page16/E1/6.jpg",
        },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/7.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page16/E1/8.jpg" },

        // Column5
        { url: "img/FriendsPlus/Page16/E1/9.jpg", audioUrl: "" },
        {
          url: "img/FriendsPlus/Page16/E1/10.jpg",
        },
        { url: "img/FriendsPlus/Page16/E1/11.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page16/E1/12.jpg" },
      ],
      [
        // Column6
        {
          url: "img/FriendsPlus/Page16/E1/13.jpg",
        },
        { url: "img/FriendsPlus/Page16/E1/14.jpg", audioUrl: "/Audios/P15/three duck.mp3" },
        {
          url: "img/FriendsPlus/Page16/E1/15.jpg",
        },
        {
          url: "img/FriendsPlus/Page16/E1/16.jpg",
          audioUrl: "/Audios/P15/how many duck.mp3",
        },
        { url: "img/FriendsPlus/Page16/E1/17.jpg" },
        { url: "img/FriendsPlus/Page16/E1/18.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page16/E1/19.jpg" },
        { url: "img/FriendsPlus/Page16/E1/20.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page16/E1/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page16/E1/22.jpg" },
        { url: "img/FriendsPlus/Page16/E1/23.jpg", audioUrl: "/Audios/P15/three duck.mp3" },
        { url: "img/FriendsPlus/Page16/E1/24.jpg" },
        { url: "img/FriendsPlus/Page16/E1/25.jpg", audioUrl: "/Audios/P15/how many duck.mp3" },
        { url: "img/FriendsPlus/Page16/E1/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page16/E1/27.jpg" },
        { url: "img/FriendsPlus/Page16/E1/28.jpg", audioUrl: "/Audios/P15/how many duck.mp3" },
        { url: "img/FriendsPlus/Page16/E1/29.jpg" },
        { url: "img/FriendsPlus/Page16/E1/30.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page16/E1/31.jpg" },
        { url: "img/FriendsPlus/Page16/E1/32.jpg", audioUrl: "" },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/33.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P16-E1",
    audio: "img/FriendsPlus/Page16/Audio/audio.e2.mp3",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page16/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page16/E2/2.jpg",
        },
        { url: "img/FriendsPlus/Page16/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page16/E2/4.jpg" },
      ],
      [
        // Column3
        {
          url: "img/FriendsPlus/Page16/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page16/E2/6.jpg",
        },
        { url: "img/FriendsPlus/Page16/E2/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page16/E2/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page16/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page16/E2/10.jpg",
        },
        { url: "img/FriendsPlus/Page16/E2/11.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page16/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page16/E2/13.jpg",
        },
        { url: "img/FriendsPlus/Page16/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page16/E2/15.jpg",
        },
        {
          url: "img/FriendsPlus/Page16/E2/16.jpg",
        },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page16/E2/17.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P16-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page16/E3/1.jpg" },
        { url: "img/FriendsPlus/Page16/E3/2.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page16/E3/3.jpg" },
      ],
      [
        // Column3
        {
          url: "img/FriendsPlus/Page16/E3/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page16/E3/5.jpg",
        },
        { url: "img/FriendsPlus/Page16/E3/6.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page16/E3/7.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page16/E3/8.jpg" },
        {
          url: "img/FriendsPlus/Page16/E3/9.jpg",
        },
        { url: "img/FriendsPlus/Page16/E3/10.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page16/E3/11.jpg" },
        {
          url: "img/FriendsPlus/Page16/E3/12.jpg",
        },
        { url: "img/FriendsPlus/Page16/E3/13.jpg" },
        {
          url: "img/FriendsPlus/Page16/E3/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page16/E3/15.jpg",
        },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page16/E3/16.jpg" },
      ],
    ],
  },
};

export default json;
