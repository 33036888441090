import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";

import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN-U1-P3-E1",
    audio: "Audios/Track 9.mp3",
    video: "Videos/Unit 4_Track 9_What s in the box.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      // [
      //   { url: "img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "img/FriendsPlus/Page3/E1/b.jpg",
      //     audioUrl: "",
      //   },
      //   { url: "img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [{ url: "img/FriendsPlus/Page3/E1/1.jpg" }],
      [
        // Column1

        { url: "img/FriendsPlus/Page3/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page3/E1/3.jpg",
          audioUrl: "Audios/Track 9.mp3",
        },
        { url: "img/FriendsPlus/Page3/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page3/E1/5.jpg" }],
    ],
  },
};

export default json;
