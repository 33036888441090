import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN1-U7-P67-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page67/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page67/E1/2.jpg" },
        { url: "img/FriendsPlus/Page67/E1/3.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page67/E1/5.jpg" },
        { url: "img/FriendsPlus/Page67/E1/6.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E1/7.jpg" },
        { url: "img/FriendsPlus/Page67/E1/8.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E1/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page67/E1/10.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "LQTAMN1-U7-P67-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    totalInput: 1,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page67/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page67/E2/2.jpg" },
        { url: "img/FriendsPlus/Page67/E2/3.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E2/4.jpg" },

        { url: "img/FriendsPlus/Page67/E2/5.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E2/6.jpg" },
        { url: "img/FriendsPlus/Page67/E2/7.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page67/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page67/E2/9.jpg" }],
    ],
  },
};

export default json;
