import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "LQTAMN1-U8-P75-E1",
    audio: "",
    video: "",
    component: UI,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page75/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page75/E1/2.jpg" },
        { url: "img/FriendsPlus/Page75/E1/3.jpg", audioUrl: "" },
        { url: "img/FriendsPlus/Page75/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page75/E1/5.jpg" }],
    ],
  },
};

export default json;
