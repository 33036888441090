import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN-U4-P37-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    component: DesignUnderLine,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    totalInput: 4,
    questionImage: [
      // Row
      // [
      //   { url: "img/FriendsPlus/Page3/E1/a.jpg" },
      //   {
      //     url: "img/FriendsPlus/Page3/E1/b.jpg",
      //     audioUrl: "",
      //   },
      //   { url: "img/FriendsPlus/Page3/E1/c.jpg" },
      // ],
      [{ url: "img/FriendsPlus/Page37/E1/a.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/12.jpg",
          audioUrl: "Audios/ball.mp3",
        },
        { url: "img/FriendsPlus/Page37/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page37/E1/14.jpg",
          audioUrl: "Audios/ball.mp3",
        },
        { url: "img/FriendsPlus/Page37/E1/15.jpg" },
      ],
      [
        // Column1

        {
          url: "img/FriendsPlus/Page37/E1/2.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/bike.mp3",
        },
        {
          url: "img/FriendsPlus/Page37/E1/3.jpg",
          input: 2,
        },
        {
          url: "img/FriendsPlus/Page37/E1/4.jpg",
          input: 3,
          isCorrect: true,
          audioUrl: "Audios/bike.mp3",
        },

        { url: "img/FriendsPlus/Page37/E1/5.jpg", input: 4 },
        { url: "img/FriendsPlus/Page37/E1/6.jpg", input: 5 },
      ],
      [
        // Column1

        {
          url: "img/FriendsPlus/Page37/E1/7.jpg",
          input: 6,
          isCorrect: true,
          audioUrl: "Audios/car.mp3",
        },
        {
          url: "img/FriendsPlus/Page37/E1/8.jpg",
          input: 7,
          audioUrl: "Audios/ball.mp3",
        },
        { url: "img/FriendsPlus/Page37/E1/9.jpg", input: 8 },

        {
          url: "img/FriendsPlus/Page37/E1/10.jpg",
          input: 9,
          isCorrect: true,
          audioUrl: "Audios/car.mp3",
        },
        { url: "img/FriendsPlus/Page37/E1/11.jpg", input: 10 },
      ],
    ],
  },
};

export default json;
