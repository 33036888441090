import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import LT2 from "../../components/ExcerciseTypes/LineTo/LT2";
import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P23-E1",
    audio: "img/FriendsPlus/Page23/Audio/audio.e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page23/E1/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page23/E1/2.jpg",
          audioUrl: "",
        },
        { url: "img/FriendsPlus/Page23/E1/3.jpg", audioUrl: "/Audios/P9/it mom.mp3",},
        { url: "img/FriendsPlus/Page23/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E1/5.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U2-P23-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page23/E2/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page23/E2/2.jpg",
          audioUrl: "",
        },
        { url: "img/FriendsPlus/Page23/E2/3.jpg", audioUrl: "/Audios/P9/it dad.mp3"},
        { url: "img/FriendsPlus/Page23/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E2/5.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN1-U2-P23-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page23/E3/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page23/E3/2.jpg",
          audioUrl: "",
        },
        { url: "img/FriendsPlus/Page23/E3/3.jpg", audioUrl: "/Audios/P9/it baby.mp3" },
        { url: "img/FriendsPlus/Page23/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E3/5.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 3",
    id: "SB1-U3-P23-E4",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page23/E4/1.jpg" }]],
  },
};

export default json;
