import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN1-U5-P49-E1",
    audio: "",
    video: "",
    component: UI,
    hideBtnFooter: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page49/E1/1.jpg" }],
    ],
  },
};

export default json;
