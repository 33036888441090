import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import TypeInNoSubmit from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P19-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page19/E1/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page19/E1/2.jpg",
        },
        { url: "img/FriendsPlus/Page19/E1/3.jpg", audioUrl: "/Audios/P15/one duck.mp3" },
        { url: "img/FriendsPlus/Page19/E1/4.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P19-E1",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page19/E2/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page19/E2/2.jpg",
        },
        { url: "img/FriendsPlus/Page19/E2/3.jpg", audioUrl: "/Audios/P15/two duck.mp3" },
        { url: "img/FriendsPlus/Page19/E2/4.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P19-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page19/E3/1.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page19/E3/2.jpg",
        },
        { url: "img/FriendsPlus/Page19/E3/3.jpg", audioUrl: "/Audios/P15/three duck.mp3" },
        { url: "img/FriendsPlus/Page19/E3/4.jpg" },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P19-E4",
    audio: "",
    video: "",
    component: D1,
    typeInput: "center",
    fontSize: 40,
    inputHeight: "100%",
    //isAllowSubmit: false,
    isLargeInput: true,
    exerciseKey: "img/FriendsPlus/Page19/E4/Key/answerKey.png",
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page19/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page19/E4/2.jpg" },
        { url: "img/FriendsPlus/Page19/E4/3.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page19/E4/4.jpg" },
        { url: "img/FriendsPlus/Page19/E4/5.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page19/E4/6.jpg" },
        { url: "img/FriendsPlus/Page19/E4/7.jpg", input: true, answer: "1" },
        { url: "img/FriendsPlus/Page19/E4/8.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page19/E4/9.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 2",
    id: "SB1-U2-P19-E5",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page19/E5/1.jpg" }]],
  },
};

export default json;
