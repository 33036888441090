import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN1-U2-P17-E1",
    audio: "",
    video: "Videos/Unit 2_Track 5_Three little ducks!.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page17/E1/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page17/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page17/E1/3.jpg",
          audioMainUrl: "/Audios/P17/Track 5.mp3",
        },
        { url: "img/FriendsPlus/Page17/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E1/5.jpg" }],
    ],
  },
};

export default json;
